import {gql} from '@apollo/client';

export const QUERY_SHOWCASE = gql`
    query onlyCashRuShowcases ($filters: OnlyCashRuShowcaseFiltersInput, $pagination: PaginationArg = {}, $sort: [String] = []) {
        onlyCashRuShowcases(filters: $filters, pagination: $pagination, sort: $sort) {
            data {
                id
                attributes {
                    headline
                    logo {
                        data {
                            id
                            attributes {
                                url
                            }
                        }
                    }
                    percent
                    age
                    term
                    URL
                    enabled
                    position
                }
            }
        }
    }
`