import {useQuery} from '@apollo/client';
import {Skeleton} from 'antd';
import _ from 'lodash';

import {
    onlyCashRuShowcases,
    onlyCashRuShowcasesVariables
} from '../../../shared/graphql/__generated__/onlyCashRuShowcases';
import {QUERY_SHOWCASE} from '../../../shared/graphql/showcase';
import {imgUrl} from '../../../shared/helpers/imgUrl';

import {Card} from './Card';

import cls from './Cards.module.scss'

export interface IOffer {
    name: string
    logo: string
    percent: string
    date: string
    value: string
    age: string
    URL: string
}

// const items:IOffer[] = [
//     {
//         name: 'Belka',
//         logo: img_belka,
//         percent: '0,8% в день',
//         date: 'от 7 до 30 дней',
//         value: 'от 1 000 до 30 000 ₽',
//         age: 'от 18 лет',
//         URL: 'https://go.leadgid.ru/aff_c?aff_id=113938&offer_id=4472&p=7445&erid=LjN8KKVCL',
//     },
//     {
//         name: 'Telezaim',
//         logo: img_telezaim,
//         percent: '0-0,8% в день',
//         date: 'от 1 дня до 12 месяцев',
//         value: 'от 1 000 до 80 000 ₽',
//         age: 'от 18 лет',
//         URL: 'https://go.leadgid.ru/aff_c?aff_id=113938&offer_id=4760&p=7445&erid=LjN8KF7rt',
//     },
//     {
//         name: 'BeriBeru',
//         logo: img_beriberu,
//         percent: '0-0,8% в день',
//         date: 'от 5 до 30 дней',
//         value: 'от 5 000 до 30 000 ₽',
//         age: 'от 18 лет',
//         URL: 'https://go.leadgid.ru/aff_c?aff_id=113938&offer_id=6082&p=7445&erid=LjN8KJZFq',
//     },
//     {
//         name: 'ZaemBeri',
//         logo: img_zaemberi,
//         percent: '0-0,8% в день',
//         date: 'от 5 дней до 12 месяцев',
//         value: 'от 5 000 до 100 000 ₽',
//         age: 'от 18 лет',
//         URL: 'https://go.leadgid.ru/aff_c?aff_id=113938&offer_id=5342&p=7445&erid=LjN8KPwQU',
//     },
//     {
//         name: 'MoiZaem',
//         logo: img_moizaem,
//         percent: '0-0,8% в день',
//         date: 'от 5 до 30 дней',
//         value: '1 000 до 100 000 ₽',
//         age: 'от 18 лет',
//         URL: 'https://go.leadgid.ru/aff_c?aff_id=113938&offer_id=6173&p=7445&erid=LjN8K6hm3',
//     },
//     {
//         name: 'ProstoZaim',
//         logo: img_prostozaim,
//         percent: '0-0,8% в день',
//         date: 'от 5 до 30 дней',
//         value: '1 000 до 100 000 ₽',
//         age: 'от 18 до 90 лет',
//         URL: 'https://go.leadgid.ru/aff_c?aff_id=113938&offer_id=6358&p=7445&erid=LjN8Jzf1o',
//     },
//
// ]

export const Cards = () => {

    const query = useQuery<onlyCashRuShowcases,onlyCashRuShowcasesVariables>(QUERY_SHOWCASE, {
        variables: {
            filters: {enabled: {eq: true}},
            sort: ['position:ASC'],
        }
    })

    if (query.loading) return <Skeleton active/>

    const items:IOffer[] = _.map(query.data?.onlyCashRuShowcases?.data, offer => (
        {
            name: offer.id!,
            logo: imgUrl(offer.attributes?.logo.data?.attributes?.url!),
            percent: offer.attributes?.percent!,
            date: offer.attributes?.term!,
            value: offer.attributes?.headline!,
            age: offer.attributes?.age!,
            URL: offer.attributes?.URL!,
        }
    ))

    console.log('items', items)

    return (
        <div className={cls.cards}>
            {_.map(items, (item) => (
                // eslint-disable-next-line react/jsx-props-no-spreading
                <Card key={item.name} {...item}/>
            ))}
        </div>
    )
}